// Sensium colors

// primary

$light-primary: #2c2a34;
$light-primary-contrast: #ffffff;

$dark-primary: #7a7492;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #00aeec;
$light-accent-contrast: #ffffff;

$dark-accent: #00aeec;
$dark-accent-contrast: #ffffff;

// common

$page-background: #2c2a341f;
